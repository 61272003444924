//wkb video
var $wkb_video = $(document).find('.wkb-video');
if( $wkb_video.length > 0 ) {
  $.each($wkb_video, function( index, video ) {
    var $video = $(video);
    var type = $video.data('type');
    var autoplay = $video.data('autoplay');

    if(autoplay) 
      $video.addClass('playing');
    
    if(type == 'file') {
      var video_el= $video.find('.video').get(0);
      
      $('.play-btn', $video).on('click', function() {
        playVideo($video, video_el);
      });
    }

    if(type == 'youtube') {
      var video_el = $video.find('iframe').get(0);

      $('.play-btn', $video).on('click', function() {
        playYoutubeVideo($video, video_el);
      });
    }

    if(type == 'vimeo') {
      var video_el= $video.find('iframe').get(0);

      $('.play-btn', $video).on('click', function() {
        playYoutubeVideo($video, video_el);
      });
    }

  });
}

function playVideo($video, video) {
  video.play();
  $video.addClass('playing');

  video.onended = function(e) {
    $video.removeClass('playing');
  };
}

function playYoutubeVideo($video, video) {
  $video.addClass('playing');

  var src = $(video).attr('src');
  src += '&autoplay=1';
  $(video).attr('src', src);
}