export default {
  init() {
    // JavaScript to be fired on all pages

    // header search form trigger
    let searchForm = $(document).find('header .search-form');
    $('.trigger', searchForm).on('click', function() {
      $(searchForm).toggleClass('active');
    });

    // Slow scroll with anchors
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('[data-toggle="tab"]').click(function (event) {
      if(!$(this).closest('.wc-tabs' ).length) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length > 0) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 500);
          }
        }
      }
    });

    //Back to top button
    let toTopButton = document.getElementById("btn-back-to-top");
    
    // When the user scrolls down 20px from the top of the document, show the button
    if(toTopButton) {

      window.onscroll = function () {
        scrollFunction();
      };
      // When the user clicks on the button, scroll to the top of the document
      toTopButton.addEventListener("click", backToTop);
    }

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        toTopButton.style.display = "block";
      } else {
        toTopButton.style.display = "none";
      }
    }
    
    function backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    // Animations with animate.css
    function callbackFunc(entries, observer) {
      entries.forEach(entry => {
        let $e = $(entry.target);
        if(entry.isIntersecting) {
          let $animation_e = $e;
          let animation = $animation_e.data('animation');
          
          setTimeout(function() {
            $e.addClass('animate_start ' + animation);
          }, 400);
        }
      });
    }
    
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    };
    
    let observer = new IntersectionObserver(callbackFunc, options);
    let $animation_elements = $('.animate__animated');

    $animation_elements.each(function( e ) {
      observer.observe($(this)[0]);
    });

    $('#modal-1').on('hidden.bs.modal', function () {
      setTimeout(function() {
        $('html, body').animate({
          scrollTop: $($('.nf-error-wrap .nf-error-msg')[0]).closest('nf-field').prev().offset().top
        }, 500);
      }, 500);
    });

    $(document).on( 'nfFormReady', function( e, layoutView ) {

      // $('form').on('focus focusout keyup submit', 'input', function() {
      //   var $errorDivs = $('.nf-error-required-error');
      //   if ( $errorDivs.length > 0 ) {
      //     $.each($errorDivs, function(index, val) {
      //       var $val = $(val);
      //       var $field = $val.closest('.nf-after-field').prev();
      //       if ($field.find('input[type="radio"]').length) {
      //         var errorMessage  = 'This field is required!';
      //         if (getCookie('pll_language') == 'sl') {
      //           errorMessage = 'Odgovor je napačen. Izberite pravi odgovor';
      //         }

      //         $val.text(errorMessage);
      //       }
      //     });
      //   }
      // });
    });

    if ( typeof Marionette == 'object' ) {
      var myCustomFieldController = Marionette.Object.extend( {
        initialize: function() {
          // On the Form Submission's field validaiton...
          var submitChannel = Backbone.Radio.channel( 'submit' );
          this.listenTo( submitChannel, 'validate:field', this.validate );
  
          this.listenTo( Backbone.Radio.channel( 'forms' ), 'submit:response', this.actionSubmit );

          // on the Field's model value change...
          var fieldsChannel = Backbone.Radio.channel( 'fields' );
          this.listenTo( fieldsChannel, 'change:modelValue', this.submitChange );
        },

        actionSubmit: function( response ) {
          $('#questionnaire').addClass('d-none');
          $('#finish').removeClass('d-none');
        },

        submitChange: function( model ) {
          console.log('submit change');

          const types = ["firstname", "lastname", "textbox", "email", "date"];

          if (model.get('type') == 'listradio') {            
            $('#nf-field-' + model.get( 'id' ) + '-container')
            .removeClass('error-not-selected')
            .find('.error-message')
            .remove();

            $('#nf-field-' + model.get( 'id' ) + '-container')
            .find('.nf-field-element li')
            .removeClass('.custom-error').removeClass('.custom-success');
          }

          setTimeout(function() {
            $(':input[type=submit]').prop('disabled', false);
          }, 500);
        },
    
        validate: function( model ) {
          console.log('validate');
          if ( 'listradio' != model.get( 'type' ) ) return;
  
          var modelID       = model.get( 'id' );
          var value         = model.get( 'value' );
          var errorID       = 'custom-field-error';
          var fieldsChannel = Backbone.Radio.channel( 'fields' );
  
          // VALIDATE
          // Check if selected option has calc value set. That is the right option!
          var options = model.get('options');
          var isValid = false;
          var hasSelectedOption = false;
          if (options.length > 0) {
            options.forEach((element) => {
              if (element.value == value && element.calc != '') {
                isValid = true;
              }
            });
          }
  
          // Reset fields
          $('#nf-field-' + modelID + '-container')
            .find('.custom-error')
            .removeClass('custom-error')
            .find('.error-message')
            .remove();
          $('#nf-field-' + modelID + '-container')
            .find('.error-not-selected')
            .removeClass('error-not-selected')
            .find('.error-message')
            .remove();
            
          $('#nf-field-' + modelID + '-container').find('.nf-error-msg.nf-error-custom-field-error').remove();
          $('#nf-field-' + modelID + '-container').find('.custom-success').removeClass('custom-success');
          $('#nf-field-' + modelID + '-container').removeClass('error-not-selected');
          $('.floating-error').hide();

          // If validation fails...
          var errorMessageNoneSelected  = 'This answer is mandatory. Please select option.';
          if (getCookie('pll_language') == 'sl') {
            errorMessageNoneSelected = 'Odgovor je obvezen. Izberite odgovor';
          }
          
          // If validation fails...
          var errorMessage  = 'This answer is not correct. Please choose again.';
          if (getCookie('pll_language') == 'sl') {
            errorMessage = 'Odgovor je napačen. Izberite pravi odgovor';
          }

          if (!$('#nf-field-' + modelID + '-container').find('input').is(':checked')) {
            if (!$('#nf-error-' + modelID).find('.error-message-none-selected').length) $('#nf-error-' + modelID).append('<div class="nf-error-msg nf-error-required-error error-message error-message-none-selected">' + errorMessageNoneSelected + '</div>');
            $('#nf-field-' + modelID + '-container').addClass('error-not-selected');
          }

          if (!isValid) {  
            // Add Error
            fieldsChannel.request( 'add:error', modelID, errorID, errorMessage );
  
            // Add error class to specific answer
            $('#nf-field-' + modelID + '-container').find('input:checked').parent().addClass('custom-error').append('<div class="error-message">' + errorMessage + '</div>');
              setTimeout(function() {

                if (window.innerWidth > 1599) {
                  $('html, body').animate({
                    scrollTop: $($('.nf-error-wrap .nf-error-msg')[0]).closest('nf-field').prev().offset().top
                  }, 100);

                  $('.floating-error').css('top', $($('.error-message')[0]).offset.top).show();
                  
                } else {
                  $('#modal-1').modal('show');
                }

              }, 150);
          
          } else {
            fieldsChannel.request( 'remove:error', modelID, errorID );
            $('#nf-field-' + modelID + '-container').find('input:checked').parent().addClass('custom-success');
          }
        }
      });
  
      jQuery( document ).ready( function( $ ) {
        new myCustomFieldController();
      });
    }

    function getCookie( name ) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
