import {HELPERS} from "../classes/helpers";

//disable click events on links
$(document).on('click', 'a[disabled], a.disabled', function(e) {
  e.preventDefault();
  return;
});


let forwardDown = $('#forward_down');
if( forwardDown.length ) {
  var targetId = forwardDown.data("target");
  var alignWithId = forwardDown.data("align-with");
  

  var $target, $alignWith;

  if (targetId != "") {
    $target = $(targetId);
  }
  if (alignWithId != ""){
    $alignWith = $(alignWithId);
  }

  forwardDown.on('click', function(e) {
    scrollTo($target, $alignWith);  
    e.preventDefault();
  });

  $(window).on('resize', function() {
    forwardDown.off('click');
    forwardDown.on('click', function(e) {
      scrollTo($target, $alignWith);  
      e.preventDefault();
    });
  });
}

function scrollTo($target, $alignWith) {
  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      topOffset = 0;

  //desktop with target
  if (width > 991 && height > 851) {
    if ($target.length) {
      topOffset = $target.offset().top;

      if ($alignWith.length) {
        var alOffset = $alignWith.offset().top;
        if (topOffset > 0) topOffset -= alOffset;
      }
      $('html,body').animate({scrollTop: topOffset},'slow');
    }
    else {
      $('html,body').animate({scrollTop: 800},'slow');
    }
  }
  //mobile view
  else {
    if ($target.length) {
      topOffset = $target.offset().top;
      var elHeight = $target.height();
      if (elHeight < height) {
        topOffset = topOffset - ((height / 2) - (elHeight / 2));
      }
      $('html,body').animate({scrollTop: topOffset},'slow');
    }
    else {
      $('html,body').animate({scrollTop: 1100},'slow');
    }
  }
}

// buttons a
var $tabControllers = $('button[role="tab"]');
if ($tabControllers.length) {
  
  $tabControllers.each(function() {
    var thisId = $(this).attr('id');
    var targetId = $(this).attr("aria-controls");
    if (targetId !== undefined && targetId.length > 0) {

      var $target = $("#" + targetId);
      if ($target.length) {
        $(this).on('click', function() {
          $('.tab-content > .tab-pane').removeClass('active');
          $target.addClass('active');
          var targetOffset = $target.offset().top;
          if (thisId != undefined && thisId == "read-terms") {
            if (HELPERS.readCookie('read-terms') == undefined) {
              HELPERS.createCookie('read-terms', 1);
              $target.find('.q-phase-1').addClass('d-none');
              $target.find('.q-phase-2').removeClass('d-none');
            }
            if ($("#questionnaire").length) {
              targetOffset = $("#questionnaire").offset().top - 80; //add some space above
            }
          }
          //scroll to target
          setTimeout(() => 
            {$('html,body').animate({scrollTop: targetOffset},'fast');            
          }, 100); 
        });
      }
    }
  });
}

// check if cookie has been set
$(window).on('load', function() {
  if (HELPERS.readCookie('read-terms') == 1) {
    $(document).find('.q-phase-1').addClass('d-none');
    $(document).find('.q-phase-2').removeClass('d-none');
  }
  else {
    $(document).find('.q-phase-1').removeClass('d-none');
  }

  // enable tab-pane page via id
  var hash = window.location.hash;
  if (hash != "") {
    var el = $(hash);
    if (el.length) {
      if (el.hasClass('tab-pane')) {
        $('.tab-pane').removeClass('active');
        el.addClass('active');
      }
    }
  }
});